/**
 * @author Varga Zoltán
 * @param message
 * @param title
 * @param callback
 * @constructor
 */

var BootboxPopup = function(message, title, callback){
    this.message = message || '';
    this.title = title || null;
    this.callback = callback || null;
    this.instance = null;
    this.settings = null;
    this.backdrop = true;
    this.outcancel = false; //TODO: ha nem szeretnék, hogy kikattintásra, vagy esc-re bezáródjon, akkor ez a settings állítható legyen
    // this.callback = function(){console.debug('TODO: callback');};

    this.confirm = function(backdrop, callback){
        this.backdrop = (backdrop == undefined ? true : backdrop);
        this.callback = (callback == undefined ? this.callback : callback);

        if(!this.settings) {
            this.settings = {
                message: this.message,
                title: this.title,
                buttons: {
                    confirm: {
                        label: 'Yes',
                        className: 'btn-success'
                    },
                    cancel: {
                        label: 'No',
                        className: 'btn-danger'
                    }
                },
                callback: callback,
                backdrop: backdrop,
            };
        } else {
            this.settingsMerge();
        }

        this.instance = bootbox.confirm(this.settings);
        this.bootboxAfter();
        setTimeout(function() { $(".bootbox .btn-success").focus(); }, 1000);


        return this;
    };

    this.dialog = function(backdrop){
        this.backdrop = (backdrop == undefined ? true : backdrop);

        if(!this.settings) {
            this.settings = {
                message: this.message,
                title: this.title,
                backdrop: backdrop,
            };
        } else {
            this.settingsMerge();
        }

        this.instance = bootbox.dialog(this.settings);
        this.bootboxAfter();
        if(typeof this.callback == 'function'){
            this.callback.call();
        }

        return this;
    };

    this.alert = function(backdrop){
        this.backdrop = (backdrop == undefined ? true : backdrop);

        if(!this.settings) {
            this.settings = {
                message: this.message,
                title: this.title,
                backdrop: backdrop,
            };
        } else {
            this.settingsMerge();
        }

        this.instance = bootbox.alert(this.settings);
        this.bootboxAfter();

        return this;
    };



    this.close = function(){
        this.instance.modal('hide');
    };

    this.setSettings = function(settings) {
        this.settings = settings;
        return this;
    };

    this.mergeSettings = function(settings){
        this.settings = $.extend(this.settings, settings);
        return this;
    };

    this.settingsMerge = function() {
        this.settings.message = this.message;
        this.settings.title = this.title;
        this.settings.backdrop = this.backdrop;
        this.settings.callback = this.callback;
    };

    this.closeEvent = function(){
        $(this.instance).on('hidden.bs.modal', function (e) {
            popupCount--;
            popupInstance.pop();

            if(popupCount > 0){
                $('body').addClass('modal-open');
            }
        })
    };

    this.bootboxAfter = function(){
        popupCount++;
        popupInstance.push(this);
        this.closeEvent();
    };

    this.outCancel = function(cancel){
        this.outcancel = cancel;
        return this;
    };
};

var popupCount = 0;
var popupInstance = [];

function popup(message, title, callback) {
    return new BootboxPopup(message, title, callback);
}

function closeLastPopup(){
    if(popupInstance[popupInstance.length - 1] !== undefined) {
        popupInstance[popupInstance.length - 1].close();
    }
}

function closeAllPopup() {
    var length = popupInstance.length;

    for(var i=length; i>0; i--){
        popupInstance[i - 1].close();
    }
}

//Esc-re bezárja a modális ablakot
$(document).keyup(function (e) {
    if (e.which == 27 && $('body').hasClass('modal-open'))
    {
        if(popupInstance.length > 0){
            popupInstance[popupInstance.length - 1].close();
        } else {
            modal.hidden();
        }
    }
});

$(document).click(function (e)
{
    if(popupInstance.length > 0){
        if (($(e.target).hasClass('modal') || $(e.target).hasClass('modal-backdrop')) && $(e.target).hasClass('fade')) {
            if(popupInstance[popupInstance.length - 1].outcancel == true){
                popupInstance[popupInstance.length - 1].close();
            }
        }
    }
});