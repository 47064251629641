/**
 * @author Varga Zoltán
 * @constructor
 */
var MyArrow = function(svgID)
{
    this.svgID = svgID
    this.arrows = [];
    this.drawingArea = null;


    this.addArrow = function(x1, y1, x2, y2, weight) {
        this.arrows.pop(new Arrow(x1, y1, x2, y2, weight));
    };

    this.init = function() {
        this.drawingArea = SVG(this.svgID).viewbox(0, 0, 300, 140);
    };

    this.draw = function() {
        var length = this.arrows.length;
console.debug(this.arrows, this.arrows.length)
        for(var i=0; i<length; i++)
        {
            this.drawArrow(this.arrows[i]);
        }
    };

    this.drawArrow = function(arrow) {
        console.debug(arrow)
    }

    this.init();
};


/**
 * @author Varga Zoltán
 * @param start
 * @param end
 * @param weight
 * @constructor
 */
var Arrow = function(x1, y1, x2, y2, weight)
{
    this.x1 = (x1 || 0);
    this.y1 = (y1 || 0);
    this.x2 = (x2 || 0);
    this.y2 = (y2 || 0);
    this.weight = (weight || 1);
};
